.slide {
  /* Ajusta el ancho según tus necesidades */
  width: 300px;
  /* Ajusta la altura según tus necesidades */
  height: 400px;
  display: flex;
  /* Centra el contenido verticalmente */
  align-items: center;
  /* Centra el contenido horizontalmente */
  justify-content: center;
}

.slide .slide-item .icon-box {
  height: 350px;
}