.text-justificado {
    text-align: justify;
}

.box {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 16px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    line-break: anywhere;
}

.subtitle-notice-detail {
    margin-top: 20px;
    margin-bottom: 20px;
}

.subtitle-notice-detail h2 {
    color: #37517e;
    font-size: 24px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
}

.link {
    text-decoration: none;
    color: #37517e;
    font-size: large;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.link:hover {
    color: #47b2e4;
}