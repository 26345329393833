/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    width: 50%;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .modal-content img {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 80vh; /* Limitar la altura máxima de la imagen */
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    .modal-content {
      max-width: 95%;
      padding: 10px;
    }
  }
  