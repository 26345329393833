.text-color-primary {
  color: #203b79;
}

.text-color-secondary {
  color: #77b735;
}

.text-xs {
  font-size: 0.75rem;
  /* 12px */
  line-height: 1rem;
  /* 16px */
}

.text-sm {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
}

.text-base {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
}

.text-lg {
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
}

.text-xl {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.75rem;
  /* 28px */
}

.text-opacity-90 {
  opacity: 0.8;
}

.text-2xl {
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
}

.text-pretty-2 {
  display: -webkit-box;
  /* Utiliza un contenedor flexible */
  -webkit-line-clamp: 2;
  /* Limita el texto a 2 líneas */
  -webkit-box-orient: vertical;
  /* Orientación del contenedor en vertical */
  overflow: hidden;
  /* Oculta el contenido que excede el contenedor */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos al final del texto truncado */
}

.text-pretty-3 {
  display: -webkit-box;
  /* Utiliza un contenedor flexible */
  -webkit-line-clamp: 3;
  /* Limita el texto a 2 líneas */
  -webkit-box-orient: vertical;
  /* Orientación del contenedor en vertical */
  overflow: hidden;
  /* Oculta el contenido que excede el contenedor */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos al final del texto truncado */
}

.main-container {
  max-width: 1300px;
  margin: 0 auto;
}

.fl-btn-primary {
  padding: 6px;
  background-color: #203b79;
  color: white;
  border: 0px;
  border-radius: 10px;
}

.fl-btn-primary:hover,
.fl-btn-danger:hover {
  color: white;
  background-color: #203b79;
  opacity: 0.95;
}

.fl-btn-danger {
  padding: 6px;
  background-color: #dc3545;
  color: white;
  border: 0px;
  border-radius: 10px;
}

.header {
  background-color: #203b79;
  height: 250px;
  margin-bottom: 16px;
  color: white;
  padding: 30px 120px;
}

.btn-outline {
  border: 2px solid #203b79;
  /* Color del borde */
  background-color: white;
  /* Color de fondo */
  color: #203b79;
  /* Color del texto */
  cursor: pointer;
  /* Cambia el cursor al pasar sobre el botón */
  border-radius: 7px;
  /* Bordes redondeados */
  transition: background-color 0.3s, color 0.3s;
  /* Efecto de transición */
}

.btn-outline:hover {
  background-color: #203b79;
  /* Color de fondo al pasar el ratón */
  color: white;
  /* Color del texto al pasar el ratón */
}

.req-list>li:hover {
  color: #dc3545;
}

.compact-textarea {
  field-sizing: content;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #203b79;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}