.card-limit-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 6em;
}

.card-text-justify {
  text-align: justify;
}

/* .card {
  display: flex;
  width: 600px;
  height: auto;
  flex-direction: column;
  border: 1px solid #ddd;
} */

.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-bg-color {
  border: 3px solid #77B735;
  background: transparent;
  color: #77B735;
}

.btn-bg-color:hover {
  color: white;
  background: #77B735;
}