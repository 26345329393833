.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #77b735;
    border-radius: 50%;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
    box-shadow: none;
}

.carousel-inner {
    text-align: center;
}

.carousel-item img {
    max-width: 100%;
    height: auto;
    margin: auto;
}

.no-images {
    height: 0;
}
