.logoflm {
    height: 150px;
    width: auto;
}

.texto-justificado {
    text-align: justify;
}

.sucursal-col {
    padding-bottom: 30px;
}

.logovertical {
    max-width: 330px;
}

.logovertical-gerencia {
    max-width: 260px;
    margin-top: 10px;
}

@media (min-width: 1024px) and (max-width: 1400px) {
    .logovertical {
        margin-top: 10px;
        width: 50%;
    }
}

.texto-grande {
    font-size: 1rem;
}

.cursorp {
    cursor: pointer;
}

.borde-ticket {
    padding: 3px 10px;
    border: rgb(135, 177, 216) 5px dashed;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.cabecera-ticket {
    background-color: rgb(185, 226, 253);
}

.bgfila {
    background-color: rgb(227, 242, 255) !important;
}

.image-credito-violeta {
    width: 58%;
}

.image-credito-ecofuturo {
    width: 55% !important;
}

.image-credito-futunavideno {
    width: 58%;
}

.image-credito-inmobiliario {
    width: 60%;
}

.image-credito-consumo {
    width: 60%;
}

.image-credito-microcredito {
    width: 60%;
}

.image-educuacion-financiera {
    width: 60%;
}

.image-cuenta-ahorros {
    width: 60%;
}

.image-cuenta-ahorros-inversion {
    width: 60%;
}

.image-cuenta-ahorros-junior {
    width: 60%;
}

.image-cuenta-ahorros-programado {
    width: 60%;
}

.hover-scale {
    transition: transform 0.3s ease-in-out;
}

.hover-scale:hover {
    transform: scale(1.05);
}