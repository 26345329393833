.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.card-content {
  width: 250px;
  height: 300px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  transition: 0.5s ease-out;
  overflow: visible;
}

.card-content span {
  font-weight: bold;
  color: #203b79;
  text-align: center;
  display: block;
  font-size: 20px;
}

.card-content .job {
  font-weight: 400;
  color: white;
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 12px;
}

.card-content .img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.card-content .img img {
  width: 100%;
  max-width: 200px;
  height: 200px;
  object-fit: contain;
}

.card-button {
  transform: translate(-50%, 125%);
  width: 60%;
  border-radius: 1rem;
  border: none;
  background-color: #77B735;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  position: relative;
  left: 50%;
  bottom: 5px;
  opacity: 0;
  transition: 0.3s ease-out;
}

.card-content:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}

#cardComoGanar {
  width: 230px;
  height: 230px;
}

.modal-dialog {
  position: relative;
  width: auto;
  max-width: 800px !important;
  text-align: center;
}

.modal-content {
  padding: 0;
}

.modal-content img {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.modal-header {
  background-color: #203b79;
}

.btn-close {
  position: relative;
  right: 15px;
  font-size: larger;
  filter: invert(1) grayscale(100%) brightness(200%);
}

.swiper .swiper-content {
  height: 340px;
}

.award-name {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #203b79;
  margin-top: 10px;
}

.award-details {
  text-align: left;
  font-size: 16px;
  color: #444;
  width: 100%;
}

.award-items {
  font-size: 18px;
}

.award-label {
  font-weight: 600;
  font-size: 18px;
  color: #77b735;
  margin-bottom: 8px;
}

.award-description {
  font-size: 15px;
  color: #444;
  background: #f8f8f8;
  padding: 10px;
  border-left: 4px solid #77b735;
  border-radius: 10px;
  font-style: italic;
}

.limited-label {
  position: absolute;
  background-color: #e60055;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
}

.card-points {
  width: 250px;
  background-color: #203b79;
  border-radius: 15px;
  display: flex;
  color: white;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.points-text {
  font-size: 60px;
  text-align: center;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.title-text {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.box {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 50px 30px;
  background: #fff;
  border-radius: 15px;
}