.section-title {
    padding-top: 100px;
}

.section-title h2 {
    color: #37517e;
    font-size: 32px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
}

.title-category {
    text-align: center;
    color: #37517e;
    font-size: 20px;
    font-weight: 700;
    position: relative;
    text-transform: capitalize;
}

.group-buttons {
    flex-direction: column;
    gap: 10px;
}

.buttons-category {
    margin: 10px;
}

.buttons-category button {
    width: 100%;
}

.container-category {
    border: 1px solid #37517e;
    border-radius: 5px;
    padding-top: 5px;
    margin-bottom: 30px;
}

.card {
    border-color: #37517e;
}